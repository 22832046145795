const observer = new IntersectionObserver (intersections => intersections.forEach(intersection => {
  if (intersection.isIntersecting) {
    if (intersection.target.visibleCallback)
      intersection.target.visibleCallback()
  }
}), { threshold: 0, rootMargin: '0px 0px 100% 100%' })

export class LazyContent extends HTMLElement {
  constructor() {
    super()
    observer.observe(this)
  }

  visibleCallback() {
    for (const template of this.querySelectorAll(':scope > template')) {
      this.replaceChildren()
      this.append(template.content)
      template.remove()
    }
  }
}
